import clsx from 'clsx'
import TypographyParagraph from '../typography/TypographyParagraph'
import {
	CalloutErrorIcon,
	CalloutHelpIcon,
	CalloutSuccessIcon,
	CalloutWarningIcon,
} from '../icons'

const Callout = ({
	variant = 'help',
	content,
	additionalClasses = '',
	...props
}: {
	variant: 'help' | 'error' | 'success' | 'warning'
	content: string
	additionalClasses?: string
}) => {
	return (
		<div
			className={clsx(
				variant === 'error' &&
					'bg-[#FFF5F5] dark:border dark:border-solid dark:border-[#FF0000] dark:bg-[#FFFBF11A]',
				variant === 'help' &&
					'bg-[#F7F7FF] dark:border dark:border-solid dark:border-[#4B4BFF] dark:bg-[#FFFBF11A]',
				variant === 'success' &&
					'bg-[#F5FFF5] dark:border dark:border-solid dark:border-[#008A0D] dark:bg-[#FFFBF11A]',
				variant === 'warning' &&
					'bg-[#FFFBF1] dark:border dark:border-solid dark:border-[#D47307] dark:bg-[#FFFBF11A]',
				'flex items-start rounded-md p-3',
				additionalClasses,
			)}
			{...props}
		>
			<div className="min-w-5">
				{variant === 'error' && <CalloutErrorIcon />}
				{variant === 'help' && <CalloutHelpIcon />}
				{variant === 'success' && <CalloutSuccessIcon />}
				{variant === 'warning' && <CalloutWarningIcon />}
			</div>
			<div>
				<TypographyParagraph
					size="small"
					additionalClasses="ml-3 text-textPrimaryLight dark:text-textPrimaryDark break-words"
				>
					{content}
				</TypographyParagraph>
			</div>
		</div>
	)
}

export default Callout
